<template>
  <div>
    <div class="information-step">
      <div class="step" :class="{ stepSelect: item.index <= index }" v-for="(item, key) in dataList" :key="item.index">
        <div class="stepIcon" :class="{ stepCompleted: item.index < index }">{{ item.index + 1 }}</div>
        <span class="stepSpan" >{{ item.text }}</span>
        <div class="stepLine" v-if="key != dataList.length - 1"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ZwxStep',
  props: {
    index: { type: Number, default: 3 },
    dataList: { type: Array, default: () => [] },
  },
  data() {
    return {}
  },
  methods: {},
}
</script>
<style lang="less" scoped>
.information-step {
  justify-content: center;
  padding: 20px 72px;
  display: flex;
  font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Medium;
  .step {
    max-width: 440px;
    display: flex;
    align-items: center;
    .stepIcon {
      width: 28px;
      height: 28px;
      background: rgba(255, 255, 255, 0);
      border: 1px solid #99adc5;
      border-radius: 50% 50%;
      line-height: 28px;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      color: #99adc5;

    }

    .stepSpan {
      color: #99adc5;
      margin-left: 10px;
    }
    .stepLastSpan{
      width: auto;
    }
    .stepLine {
      margin: 0 20px;
      width: 300px;
      height: 1px;
      background: #99adc5;
    }
  }
  .stepCompleted{
    background: #ffffff !important;
    color: #1ca06a !important;
    border: 1px solid #1ca06a !important;
  }
  .stepSelect {
    .stepIcon {
      background: #1ca06a;
      color: #fff ;
    }
    .stepSpan {
      color: #1ca06a !important;
    }
    .stepLine {
      background: #1ca06a;
    }
  }
}
</style>
