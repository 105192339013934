var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "information-step" },
      _vm._l(_vm.dataList, function(item, key) {
        return _c(
          "div",
          {
            key: item.index,
            staticClass: "step",
            class: { stepSelect: item.index <= _vm.index }
          },
          [
            _c(
              "div",
              {
                staticClass: "stepIcon",
                class: { stepCompleted: item.index < _vm.index }
              },
              [_vm._v(_vm._s(item.index + 1))]
            ),
            _c("span", { staticClass: "stepSpan" }, [
              _vm._v(_vm._s(item.text))
            ]),
            key != _vm.dataList.length - 1
              ? _c("div", { staticClass: "stepLine" })
              : _vm._e()
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }